@import "../global.scss";

.uiElement {
  display: inline-flex;
  float: left;
}

.uiCheckboxStack {
  margin-bottom: 0.8rem;
  margin-top: 0.25rem;
}

.uiCheckbox {
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  cursor: pointer;
}

.uiElement__Container {
  flex-direction: row;
  align-items: center;
}

.uiElement__Text {
  font-weight: 300;
  color: #222;
}

.uiElement__Label {
  width: 8rem;
  font-size: small;
}

.uiElement__TextElement {
  min-width: 18rem;
  max-width: 23rem;
  font-size: smaller;
}

.uiElement__InputWithBox {
  margin-bottom: 0.25rem;
}

.uiElement__Switch {
  margin-top: 0.25rem;
}

.MuiAutocomplete-listbox,
.MuiAutocomplete-paper {
  width: 23rem;
}
.MuiInputAdornment-positionEnd {
  margin-left: -1.25rem;
}

.MuiCheckbox-root {
  padding: 0;
}

.statusIcon__Text {
  font-size: 14px;
  font-weight: bold;
  padding-left: 0.2rem;
}

.artikelSucheResponseCard {
  border: 1px solid #222;
  border-radius: 0.5rem;
  margin-top: 2rem;
  padding-bottom: 1rem;
}

.box-with-centered-content {
  display: flex;
  justify-content: center;
}

.checkboxActionProgress {
  position: absolute;
  top: 4px;
  z-index: 10;
}
.MuiBackdrop-root,
.MuiDialog-backdrop {
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}
