.zurBearbeitungRecords .prufung {
  min-height: 2rem;
  margin-top: 1rem;
  background-color: #f5f5f5;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
}

.zurBearbeitungRecords .prufungColor {
  color: #e81b35;
}
