.accordion::before {
  display: none; //entfernt die zusätzliche obere Randlinie
}

.accordionSummary {
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.accordionSummaryRoot {
  height: 2px;
}
