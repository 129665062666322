.w_12rem {
  width: 12rem;
  max-width: 12rem;
}

.aktiveAutomatenLink {
  padding: 0;
  justify-content: flex-start;
}

.grid_item_30rem {
  max-width: 32rem;
  margin-bottom: 1rem;
}

.grid_item_40rem {
  max-width: 40rem;
  margin-bottom: 1rem;
}

.bold-data-grid-row {
  background-color: whitesmoke !important;
  font-weight: bold !important;
}
