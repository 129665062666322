.datenpflegeRnsStack {
    display: flex;
    flex-direction: row;
    padding: 2rem 2rem 0rem 2rem;
}
.rnsButton {
    border-radius: 6px;
    min-width: 9rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.rnsButtonBox {
    display: flex;
    align-items: center;
}

.rnsTextBox {
    display: flex;
    min-height: 44px;
}

.rnsButtonActionProgress{
    margin-top: 2;
    margin-bottom: 2;
    margin-left: 1rem;
    margin-right: 1rem;
}
