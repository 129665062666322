.inputTextFieldForNotes {
  min-width: 11rem;
}

.fileNameTextInput {
  min-width: 14rem;
}

.noteDialogTextInput {
  min-width: 26rem;
  font-size: smaller;
}

.labelForNotes {
  width: 5.6rem;
  white-space: nowrap;
}

.fontForNotes {
  font-size: small;
  font-weight: 300;
  color: #222;
}

.createNewNoteButton {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: fit-content;
}

@media (max-width: 900px) {
  .noteTextInput {
    width: 11rem;
  }
}

@media (min-width: 900px) {
  .noteTextInput {
    width: 23rem;
  }
}

@media (min-width: 1200px) {
  .noteTextInput {
    width: 42rem;
  }
}

@media (min-width: 1536px) {
  .noteTextInput {
    width: 64rem;
  }
}
