.suche-tabs {
  min-height: 2rem;
  margin-top: 0.5rem;
  background-color: #f5f5f5;
}

.suche-tabs .MuiTabs-flexContainer {
  height: 2rem;
}

.suche-tabs .static-tab-element {
  min-height: 2rem;
}

.suche-tabs .tab-element {
  min-height: 2rem;
  padding: 0;
}
.suche-tabs .tab-div {
  width: 100%;
  padding-left: 1rem;
}

.suche-tabs .tab-stack {
  width: "100%";
}

.suche-tabs .tab-element .MuiButtonBase-root {
  background: transparent;
}
