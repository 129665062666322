.anwendernachrichten__AutocompleteContainer{
    min-width: 5.5rem !important;
    max-width: 5.5rem !important;
}

.anwendernachrichten__Label {
    min-width: 10rem !important;
    max-width: 10rem !important;
}

.anwendernachrichten__TextStyle {
    font-size: large;
    font-weight: 300;
    -webkit-font-smoothing: subpixel-antialiased;
}

.anwendernachrichten__inputTextFieldForNachricht{
    min-width: 70% !important;
}
