.glnChangeButton {
  border-radius: 6px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.checkboxCells {
  text-align: center;
  margin-top: -3px;
}

.checkboxColumn {
  min-width: 230px;
}

.glnSearchHeight {
  min-height: 3.7rem;
}

.serialNumberCells {
  text-align: center;
  margin-top: -2px;
}

.searchAction {
  margin-top: -1.5rem;
  margin-bottom: -1rem;
}
