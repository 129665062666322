.tableContainer {
  display: table-caption;
  min-width: 16rem;
}

.tableHeadCell {
  font-size: xx-small;
  line-height: 0.5rem;
  padding: 0.5rem;
}

.tableBodyCell {
  font-size: 0.8rem;
  line-height: 0.5rem;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
}

.keineDatenCell {
  padding: 0.5rem;
  text-align: center;
  font-size: small;
}
