.auslastungverfuegbarkeit__Grid {
  max-width: 28rem;
}

.auslastungverfuegbarkeit__LabelStyle {
  width: 7.5rem;
  white-space: nowrap;
  font-size: large;
  font-weight: 300;
  -webkit-font-smoothing: subpixel-antialiased;
}

.auslastungverfuegbarkeit__InputStyle {
  width: 16rem;
  font-size: smaller;
  margin-right: 1rem;
}
