.inputTextFieldForKaufanfragenStatus {
    min-width: 16rem;
    max-width: 80rem;
}

.dialogHeaderLabelStyle{
    font-size: large;
    padding-top: 0.5rem;
}

.dialogLabelStyle {
    width: 7rem;
    white-space: nowrap;
}

.kaufanfragenDialogInput {
    width: 16rem;
    font-size: smaller;
    margin-right: 1rem;
}

.dialogTextStyle{
    font-size: large;
    font-weight: 300;
    -webkit-font-smoothing: subpixel-antialiased;
}
.dialogTextErrorStyle {
    font-size: small;
    font-weight: 300;
    color: orangered;
    -webkit-font-smoothing: subpixel-antialiased;
}
