.labelForLogfile {
    width: 4rem;
    white-space: nowrap;
}

.logfile__Header{
    flex-direction: row;
    align-items: center;
    height: 35px;
    min-width: 14rem;
}

.logfile__AutocompleteContainer {
    min-width: 14rem;
    max-width: 14rem;
}

.logfile__inputTextFieldForDate {
    min-width: 14rem;
}

.logfile__buttonFilter{
    margin-top: 1rem;
    margin-bottom: 1rem;
}
