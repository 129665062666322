.labelContainer {
    display: flex;
    align-items: center;
    padding: 0.25rem;
    padding-right: 1rem;
}
.labelIconContainer {
    margin-right: 0.5rem;
}
.labelText {
    font-weight: inherit;
    flex-grow: 1;
}
