.rowStack {
  flex-direction: row;
  align-items: flex-end;
}

.filterButtons {
  column-gap: 1em;
}

.resizeChip {
  width: 12em;
}

.exportChip {
  margin-left: 1em;
}

.buttonStack {
  margin-bottom: 5px;
}
