//Stuff from the styleguide https://app.zeplin.io/project/5fd747fa27ed8482da4f50a6

//colors
$white: #ffffff;
$secondary-main: #fbe530;

//shadow
$shadow: 0px 2px 3px 0px rgba(30, 25, 31, 0.2),
  0px 4px 6px 0px rgba(30, 25, 31, 0.2);


.mr_1rem {
  margin-right: 1rem;
}

.ml_1rem {
  margin-left: 1rem;
}

.mt_1rem {
  margin-top: 1rem;
}
