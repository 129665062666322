.relativeGrid {
  width: 100%;
  max-height: 500;
}
.ag-row .ag-cell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ag-cell-center-justified-content {
  justify-content: center !important;
}

.gridActionsRow{
  flex-direction: row;
  align-items: flex-end;
  padding: 0.25rem;
  height: 0;
}

.withGridActionsRow{
  margin-top: 48px;
  padding-bottom: 1rem;
}

.ag-theme-alpine {
  --ag-foreground-color: rgb(60, 60, 60);
  --ag-header-foreground-color: rgb(28, 101, 177);
  --ag-header-background-color: #f5f7f7;
  --ag-selected-row-background-color: rgb(28,101,177, 0.1) !important;
}
