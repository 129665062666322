.flexRow {
  flex-direction: row;
}

.paddingBottom15 {
  padding-bottom: 15px;
}

.stack {
  display: flex;
  padding-bottom: 10px;
}

.automatenblattContainer {
  flex-direction: row;
  column-gap: 2rem;
  flex: 1;
}
