.rnsStack {
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  min-width: 30rem;
  flex-direction: row;
  justify-content: flex-start;
}

.rnsInputElement {
  max-width: 23rem;
  font-size: smaller;
}

.aktiveAutomatenButton {
  margin-right: 2.28rem;
}

.serialNumberCell {
  text-align: center;
}

.gridDiv {
  width: 18rem;
}
