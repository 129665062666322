@use "global/global.scss" as g;

.app {
  background-color: hsl(0, 0%, 96%);
  flex-direction: column;
  background-color: #f5f5f5;
}

.content {
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 1rem;
  padding-top: 0;
}

.snackbarDismissButton {
  color: #f5f5f5;
}

.separator {
  min-height: 1rem;
}
