/*noinspection CssInvalidAtRule*/
@import-normalize ;

html, body, #root, .app {
    height: 100%;
}

body {
    font-family: "Roboto";
}
