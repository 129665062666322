.berichteflatfile__Grid {
    max-width: 28rem;
}

.berichteflatfile__LabelStyle {
    width: 7.5rem;
    white-space: nowrap;
    font-size: large;
    font-weight: 300;
    -webkit-font-smoothing: subpixel-antialiased;
}

.berichteflatfile__InputStyle {
    width: 16rem;
    font-size: smaller;
    margin-right: 1rem;
}
